.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.65);
  z-index: 1111;
}

.spinner {
  border: 7px solid #ff601744;
  border-left: 7px solid #ff5f17;
  border-radius: 50%;
  width: 4rem;
  aspect-ratio: 1;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
