.suggestion-list {
  overflow-y: scroll;
  scrollbar-width: none; /* for Firefox */
  -ms-overflow-style: none; /* for IE and Edge */
  border: 1px solid #D26600;
  filter: drop-shadow(0 0 0.75rem #ccc);
}

.suggestion-list::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
