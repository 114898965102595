/* src/EventBooking.css */

.event-booking {
    display: flex;
    justify-content: center;
    @media (max-width: 769px) {
      flex-direction: column;
      .booking-form{
        width: 100%;
      }
    }
  }
  
  .event-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    margin-right: 20px;
    display: grid;
    grid-template-rows: 1fr auto !important;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .event-image {
    width: 100%;
    height: auto !important;
    aspect-ratio: 5/3;
  }
  
  .event-details {
    padding: 20px;
  }
  
  .event-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ff6600;
    font-weight: bold;
  }
  
  .event-month {
    font-size: 18px;
  }
  
  .event-day {
    font-size: 36px;
  }
  
  .event-info {
    margin-top: 10px;
  }
  
  .event-title {
    font-size: 20px;
    font-weight: bold;
  }
  
  .event-location,
  .event-time {
    margin: 5px 0;
  }
  
  .booking-form {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ff6600;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .payment-methods {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .payment-methods img {
    width: 100%;

    height: auto;
  }
  
  .pay-now-button {
    width: 100%;
    padding: 10px;
    background-color: #ff6600;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  

.event-booking
{
    padding: 5%;
}