/* Default styles (for screens larger than 600px) */

.nav-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6rem;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: sticky;
  top: 0;
  z-index: 1111;
  @media (max-width: 1024px) {
    padding: 0 1rem !important;
  }
}
.scrolled {
  background-color: #F58021;
}
.left-nav
{
  display: flex;
  color: white;
}

.left-nav h1 {
  padding-left: 1rem;
  margin: 0;
  @media (max-width: 769px) {
    font-size: 2.1rem;
  }
}

.right-nav {
  display: flex; /* Changed to flex for alignment */
  align-items: center; /* Ensure items are centered vertically */
}

.right-nav ul {
  display: flex;
  list-style: none;
  align-items: center;
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
}

.right-nav ul li {
  /* margin: 0 10px; Reduced margin to fit layout */
}

.right-nav ul li a {
  text-decoration: none !important;
}

.right-nav ul li:hover {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  transform: scale(0.9);
}

/* Hamburger menu styles */
.hamburger-menu {
  display: none; /* Hide hamburger icon by default */
  cursor: pointer;
}

.hamburger-menu div {
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 5px 0;
}

.log {
  cursor: pointer;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  margin-left: 10px; /* Adjusted margin for better alignment */
  transform: scale(1);
  transition: transform 0.3s ease-in;
  &:hover{
    transform: scale(0.9);
  }
}
.io
{
  padding: 0.5rem 1rem ;
}
.right-nav ul li a {
  color: white; /* Set link color to white */
}

/* Responsive styles (for screens smaller than 600px) */
@media (max-width: 954px) {
  .nav-main{
    padding: 0 1rem;
  }
  .right-nav {
      display: none; /* Hide nav links by default on small screens */
      position: absolute;
      top: 90%; /* Adjust based on header height */
      right: 0px;
      /* background-color: black; */
      width: 100%;
      text-align: center;
      z-index: 1000;
      background-color: #f58021de;
  }

  .right-nav.active {
      display: block; /* Show nav links when menu is active */
      padding: 0 2rem 0.5rem;
      border-radius: 0 0 1rem 1rem;
  }

  .right-nav ul {
      display: block; /* Stack list items vertically */
      padding: 0;
      margin: 0;
  }

  .right-nav ul li {
      margin: 10px 0; /* Spacing between items */
  }

  .hamburger-menu {
      display: block; /* Show hamburger icon on small screens */
  }

  /* White text color for mobile menu items */
  .right-nav ul li a {
      color: white; /* Set link color to white */
  }
}
