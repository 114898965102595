/* General styles for the side navbar */
.side-navbar {
  height: 100%;
  width: 250px; /* Adjust width as needed */
  position: fixed;
  top: 0;
  left: -250px; /* Hide the navbar by default */
  background-color: #ee7d45; /* Dark background for visibility */
  transition: 0.3s;
  z-index: 1000;
  top: 111;
}

.side-navbar.open {
  left: 0; /* Show the navbar when open */
}

.side-navbar button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-btn {
  position: absolute;
  color: white;
  transform: scale(1);
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  &:hover{
    transform: scale(1.1);
    color: black;
  }
}

.open-btn {
  /* display: none; Hidden on large screens */
  position: fixed;
  background-color: #F58021;
  font-size: 1.5rem;
  border-end-end-radius: 1rem;
  height: 3rem;
  aspect-ratio: 1;
}

.side-navbar nav {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.side-navbar a {
  color: white;
  padding: 15px;
  text-decoration: none;
  display: flex;
  border-radius: 1rem;
  transition: background-color 0.3s;
}

.side-navbar a:hover {
  background-color: #d8652e; /* Darker shade of orange */
}

/* Responsive styles */
@media (max-width: 768px) {
  .side-navbar {
      width: 200px; /* Adjust for smaller screens */
  }

  .side-navbar .open-btn {
      display: block; /* Show open button on small screens */
      position: fixed;
      top: 10px;
      left: 10px;
      /* background-color: #333; */
      color: white;
      border: none;
      font-size: 24px;
      cursor: pointer;
      z-index: 1001;
  }
}

/* Sidenav.css */

.logout-icon {
  margin-top: auto; /* Pushes the icon to the bottom if using flexbox */
  font-size: 1.5rem; /* Adjust icon size */
  color: white; /* Example color */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;
}

.logout-icon:hover {
  color: black; /* Example hover color */
}
